import { BaseEnum } from "./core/BaseEnum";

export class BatteryControlTypeEnum extends BaseEnum {
    static MB = 1;
    static OPC_UA = 2;
    static IEC = 3;

    static statuses = {
        [this.MB]: "MB",
        [this.OPC_UA]: "OPC UA",
        [this.IEC]: "IEC"
    }

}