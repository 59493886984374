export default class ElectricEnergyDTO {
    unit;
    value;

    constructor(data) {
        this.unit = data.unit;
        this.value = data.value;
    }

    getValueWithUnit() {
        return `${this.value} ${this.unit}`;
    }
}