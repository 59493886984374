import { ApiConfig } from "../config/ApiConfig";
import { BessStatusEnum } from "../enums/BessStatusEnum";

export async function fetchBatteryInitialValues() {

    const response = await fetch(ApiConfig.getFormattedURL() + '/battery/initial-values/all');

    if (!response.ok) {
        throw new Error('Fetching battery initial values failed');
    }

    return response.json();
}

export async function canOperateBattery() {
    const response = await fetch(ApiConfig.getFormattedURL() + '/battery/bessStatus');

    if (!response.ok) {
        throw new Error('Fetching battery operating status failed');
    }

    const { operate } = await response.json();
    console.log("operate", operate);

    return operate !== BessStatusEnum.LOCAL;
}

export async function saveChanges(changes_obj) {
    if (!changes_obj) {
        return;
    }

    const response = await fetch(ApiConfig.getFormattedURL() + '/battery/values/update', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(changes_obj)
    });

    if (!response.ok) {
        throw new Error('Saving changes failed');
    }

    return response.json();
}