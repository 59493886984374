import { ElectricityRatesChart } from "./ElectricityRatesChart";
import { WeatherWidget } from "./WeatherWidget";
import { HeaderWidget } from "./HeaderWidget";
import CurrentPerformanceWidget from "./CurrentPerformanceWidget";
import { Overview } from "./Overview";
import { ElectricityProductionStats } from "./ElectricityProductionStats";
import { BatteryControlWidget } from "./BatteryControlWidget";
import InformationGraphWidget from "./InformationGraphWidget";
import LiveStatus from "./LiveStatus";
import Animation from "./Animation";

export function HomePage() {
  return (
    <div className={"mb-14"}>
      <HeaderWidget></HeaderWidget>
      <div
        className={
          "flex flex-row gap-11 mx-6 xs:flex-col xs:mx-4 md:flex-wrap md:gap-8 lap:flex-wrap"
        }
      >
        <WeatherWidget></WeatherWidget>
        <CurrentPerformanceWidget></CurrentPerformanceWidget>
        <Overview>
          <ElectricityProductionStats />
        </Overview>
      </div>
      <div
        className={
          "grid grid-cols-2 gap-11 mx-6 mt-6 xs:flex xs:flex-col xs:mx-4 xs:mt-11 md:flex md:flex-wrap md:gap-8 md:mt-8 lap:gap-8 lap:mt-8 lap:flex lap:flex-wrap"
        }
      >
        <BatteryControlWidget></BatteryControlWidget>
        {/* <Animation animationData={prop.animationData}></Animation> */}
        <LiveStatus></LiveStatus>
      </div>
      <div
        className={
          "grid grid-cols-2 gap-11 mx-6 mt-6  xs:flex xs:flex-col xs:mx-4 xs:mt-11 md:flex md:flex-wrap md:gap-8 md:mt-8 lap:gap-8 lap:mt-8 lap:flex lap:flex-wrap"
        }
      >
        <InformationGraphWidget></InformationGraphWidget>
        <ElectricityRatesChart></ElectricityRatesChart>
      </div>
    </div>
  );
}
