export function Select(prop) {
  return (
    <div className={"flex flex-col mt-3"}>
      {prop.label && <label className={prop.classLabel}>{prop.label}</label>}
      <select
        required={prop.required}
        placeholder={prop.placeholder}
        className={prop.className}
        type={prop.type}
        name={prop.name}
        value={prop.value}
        onChange={prop.onChange}
        defaultValue={prop.defaultValue}
      >
        {prop.options.map((option, index) => {
          return (
            <option
              value={option.value}
              selected={option.selected}
              key={option.value || index}
              className={option.className}
            >
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}
