import { useLayoutEffect, useState } from "react";
import { ElectricityRateDTO } from "../DTOs/ElectricityRateDTO";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { fetchDailyMarketByDay } from "../repositories/OteRepository";
import Modal from "./Modal";
import { fetchObjectLiveStats } from "../repositories/ElectricityRepository";
import DatePicker from "react-datepicker";

export function ElectricityRatesChart({
  day = new Date(new Date().setDate(new Date().getDate() + 1))
    .toJSON()
    .slice(0, 10),
}) {
  let [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        borderWidth: 1,
      },
    ],
  });

  const [dayCzech, setDayCzech] = useState(new Date(day));
  const [modalOpen, setModalOpen] = useState(false);
  let [load, setLoad] = useState(true);

  useLayoutEffect(() => {
    async function fetchByDay() {
      try {
        let data = await fetchDailyMarketByDay(day);

        if (!data) {
          data = await fetchDailyMarketByDay(new Date().toJSON().slice(0, 10));
        }

        setDayCzech(new Date(data[0].Date));
        setLoad(false);

        const chartData = {
          labels: [],
          datasets: [
            {
              label: "",
              data: [],
              borderWidth: 1,
              yAxisID: "y",
            },
          ],
        };

        for (const electricityRate of data) {
          const rateObject = new ElectricityRateDTO(electricityRate);

          chartData.labels.push(rateObject.hour);
          chartData.datasets[0].data.push(rateObject.price);
          chartData.datasets[0].label = `Cena elektřiny za MWh `;
        }

        setChartData(chartData);
      } catch (error) {
        console.error("Error:", error);
      }
    }

    async function fetchTest() {
      const data = await fetchObjectLiveStats();

      console.log(data);
    }
    fetchTest();
    fetchByDay().catch(console.error);
  }, [day]);

  async function handleDay(direction) {
    try {
      const currentDate = new Date();
      const nextDay = new Date(currentDate);
      if (direction === "next") {
        nextDay.setDate(nextDay.getDate() + 1);
      }

      const data = await fetchDailyMarketByDay(nextDay.toJSON().slice(0, 10));
      if (!data) {
        setModalOpen(true);
        return;
      }

      const chartData = {
        labels: [],
        datasets: [
          {
            label: "",
            data: [],
            borderWidth: 1,
            yAxisID: "y",
          },
        ],
      };

      for (const electricityRate of data) {
        const rateObject = new ElectricityRateDTO(electricityRate);
        chartData.labels.push(rateObject.hour);
        chartData.datasets[0].data.push(rateObject.price);
        chartData.datasets[0].label = `Cena elektřiny za MWh `;
      }

      setChartData(chartData);
      setDayCzech(nextDay);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: "Hodina",
          position: "bottom",
        },
      },
      y: {
        display: true,
        position: "left",
        title: {
          display: true,
          text: "Cena (Kč/MWh)",
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },

      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("cs", {
                style: "currency",
                currency: "CZK",
              }).format(context.parsed.y);
            }
            return label;
          },
          title: function (context) {
            return context.title || "";
          },
        },
      },
    },
  };

  if (load) {
    return <div></div>;
  }

  return (
    <div className={"flex-1 w-full electric-rates"}>
      <Modal
        isOpen={modalOpen}
        headline={"Upozornění"}
        text={"Nedostupná data pro vybraný den"}
        buttonText={"OK"}
        onClose={() => setModalOpen(false)}
      />
      <div
        className={
          "d-flex flex-column align-items-center py-8 px-8 flex-1 bg-gray-100 rounded-2xl h-full xs:px-2"
        }
      >
        <h1 className="text-2xl font-bold text-center p-4 pb-0">
          Data o cenách na spotovém trhu
        </h1>
        <div
          className={
            "flex gap-2 flex-row items-center justify-end xs:justify-center"
          }
        >
          {dayCzech.getDate() !== new Date().getDate() && (
            <button onClick={() => handleDay("last")} type={"button"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                />
              </svg>
            </button>
          )}
          <p className={"underline"}>
            {dayCzech.getDate() +
              "." +
              (dayCzech.getMonth() + 1) +
              "." +
              dayCzech.getFullYear()}
          </p>
          <div className="date-picker-container inline-flex  mr-2 p-5 justify-end w-max invisible order-first xs:w-px xs:px-0">
            <DatePicker
              selected={new Date()}
              onChange={() => {}}
              dateFormat="yyyy-MM-dd"
              className="custom-datepicker mx-3"
            />
          </div>
          {dayCzech.getDate() === new Date().getDate() && (
            <button onClick={() => handleDay("next")} type={"button"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m15 15 6-6m0 0-6-6m6 6H9a6 6 0 0 0 0 12h3"
                />
              </svg>
            </button>
          )}
        </div>
        <div className="graph-container w-full relative xs:!min-h-0 xs:h-96 xs:w-full">
          <Line options={chartOptions} data={chartData}></Line>
        </div>
      </div>
    </div>
  );
}
