import { BaseEnum } from "./core/BaseEnum";

export class BatteryModeEnum extends BaseEnum {
    static MANUAL = 1;
    static AUTO_P = 2;
    static AUTO_Q = 3;
    static AUTO_PQ = 4;

    static statuses = {
        [this.MANUAL]: "Manual",
        [this.AUTO_P]: "Auto P",
        [this.AUTO_Q]: "Auto Q",
        [this.AUTO_PQ]: "Auto P,Q"
    }
}