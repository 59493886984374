import { ApiConfig } from "../config/ApiConfig";

export async function fetchWeather(day) {
    const result = await fetch(ApiConfig.getFormattedURL() + `/weather/history/${day}`)

    if (!result.ok) {
        throw new Error('There was a problem connecting to weather API')
    }

    return result.json();
}