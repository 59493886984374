import { Button } from "./Button";
import { Input } from "./Input";
import { Select } from "./Select";
import { useEffect, useState } from "react";
import ConfirmModal from "./ConfirmModal";
import { canOperateBattery, fetchBatteryInitialValues, saveChanges } from "../repositories/BatteryControlRepository";
import { BatteryStatusEnum } from "../enums/BatteryStatusEnum";
import loading from "../animations/loading.json"
import Animation from "./Animation";
import { BatteryModeEnum } from "../enums/BatteryModeEnum";
import { BatteryControlTypeEnum } from "../enums/BatteryControlTypeEnum";

function translate_name_text(name) {
  switch (name) {
    case "battery_status":
      return "Změna stavu";
    case "battery_mode":
      return "Přepínání režimů";
    case "battery_control_type":
      return "Způsob ovládání";
    case "battery_active_performance":
      return "Činný výkon";
    default:
      throw new Error("Unknown name");
  }
}

const emptyValue = "";

const initial_control_values = {
  battery_status: emptyValue,
  battery_mode: emptyValue,
  battery_control_type: emptyValue,
  battery_active_performance: emptyValue,
};

export function BatteryControlWidget() {
  // three selects:
  // I. ON/OFF/RESET
  // II. Manual/Auto-P
  // III. local/remote
  // charge/discharge

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [performanceMin, setPerformanceMin] = useState(0);
  const [performanceMax, setPerformanceMax] = useState(0);

  // stores chosen values in text form
  const [control_values, setControlValues] = useState(initial_control_values);

  // stores chosen values in value form
  const [chosen_values, setChosenValues] = useState({});

  const [initial_values, setInitialValues] = useState({ ...initial_control_values });

  const [isLoading, setIsLoading] = useState(true);

  const [reload, setReload] = useState(false);

  const [isOk, setIsOk] = useState(true);

  const [canOperate, setCanOperate] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);


  const handle_input_change = (e) => {
    const input = e.target;
    const name = input.name;
    const selectedOption = input[input.selectedIndex];
    const txt = selectedOption.text;
    const value = selectedOption.value;

    if (name === "battery_mode") {
      setIsDisabled(value == "1" ? false : true);
      if(isDisabled){
        setControlValues((prev) => {
          return { ...prev, "battery_active_performance": 0 };
        });

        setChosenValues((prev) => {
          return { ...prev, "battery_active_performance": 0 };
        });
      }
    }

    if (selectedOption.classList.contains("defaultOption")) {
      setControlValues((prev) => {
        return { ...prev, [name]: initial_values[name] };
      });

      setChosenValues((prev) => {
        const newValues = { ...prev };
        delete newValues[name];
        return newValues;
      });
      return;
    }

    setControlValues((prev) => {
      return { ...prev, [name]: txt };
    });

    if (selectedOption.classList.contains("resetOption")) {
      setControlValues((prev) => {
        return { ...prev, [name]: emptyValue };
      });
    }

    setChosenValues((prev) => {
      return { ...prev, [name]: value };
    });
  };


  const openModal = () => {
    console.log(initial_values);
    console.log(control_values);
    console.log(chosen_values);
    if(!isOk){
      alert("Některé hodnoty nejsou v pořádku");
      return
    }

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const current_value_input = (key) => {
    return (
      <div className={"flex flex-row justify-between xs:justify-center"}>
        <Input
          type={"text"}
          placeholder={"Není určena"}
          label={"Aktuální hodnota"}
          readOnly={true}
          className={"flex-1 text-right"}
          classLabel={"flex-1"}
          value={initial_values[key]}
        ></Input>
      </div>
    );
  };

  const generate_select = (options, name, label) => {
    return (
      <Select
        placeholder={"Vyberte hodnotu"}
        label={label}
        defaultValue={"-1"}
        options={options}
        classLabel={"font-bold text-center"}
        name={name}
        onChange={handle_input_change}
      ></Select>
    );
  };

  useEffect(() => {

    async function checkCanOperate() {
      try {
        setIsLoading(true);
        const canOperate = await canOperateBattery();
        setCanOperate(canOperate);
        
      } catch (error) {
        console.error("Error fetching operating status:", error);

      }finally {
        setIsLoading(false);
      }


    }

    checkCanOperate();
  }, []);


  useEffect(() => {
    // load initial values
    // clear chosen values

    setChosenValues({});


    async function getInitialValues() {

      setIsLoading(true);
      try {
        const values = await fetchBatteryInitialValues();
        console.log(values);

        const initialValues = {};
        setPerformanceMin(values.performance_min);
        setPerformanceMax(values.performance_max);

        initialValues["battery_status"] = BatteryStatusEnum.toString(values.battery_status);
        initialValues["battery_mode"] = BatteryModeEnum.toString(values.battery_mode);
        initialValues["battery_control_type"] = BatteryControlTypeEnum.toString(values.battery_control_type);
        initialValues["battery_active_performance"] = values.battery_active_performance + " kW";

        if(initialValues["battery_mode"] == "Manual"){
          setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }


        setInitialValues(initialValues);
        
      } catch (error) {
        console.error("Error fetching initial values:", error);
      }finally {
        setIsLoading(false);
      }

      
    }

    if(canOperate){
      getInitialValues();
    }

  }, [reload, canOperate]);

  const save_changes = async () => {
    // save changes
    console.log(chosen_values);
    if(Object.keys(chosen_values).length === 0){
      closeModal();
    }else{
      const response = await saveChanges(chosen_values);
      console.log("response", response);
      closeModal();

      
      setIsLoading(true);
      
      setTimeout(() => {
        setReload((prev) => !prev);
      }, 1500);
    }
    

  };


  const generate_table_changes = () => {
    const rows = Object.keys(chosen_values).length !== 0 ? Object.keys(chosen_values)
      .map((key) => {
        if (key === "battery_active_performance" && isDisabled) {
          return null;
        }
          return (
            <tr className={"text-center border"} key={key}>
              <td className={"border p-2"}>{translate_name_text(key)}</td>
              <td className={"border p-2"}>{initial_values[key]}</td>
              <td className={"border p-2"}>{control_values[key]}</td>
            </tr>
          );
      })
    : [];

    return (
      <>
        {rows.length !== 0 ? (
          <table className={"mx-auto w-full border mt-11"}>
            <thead>
              <tr className={"text-center border"}>
                <th className={"border p-2"}>Parametr</th>
                <th className={"border p-2"}>Aktuální hodnota</th>
                <th className={"border p-2"}>Nová hodnota</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        ) : (
          <span className="font-bold">Nebyly provedeny žádné změny</span>
        )}
      </>
    );
  };

  if(isLoading){
    return <Animation animationData={loading} heading={"Ovládání BESS"}/>
  }

  if(!canOperate){
    return (
      <div className=" bg-white rounded-xl shadow-lg flex-1  py-8 px-8">
        <h1 className="text-2xl font-bold text-center p-4 pb-0">
          Ovládání BESS
        </h1>
        <div className="h-full xs:h-auto text-center mt-20">
          <span className="text-center text-xl font-bold text-red-500">Prosím o změnu ovládání na Remote, aby šlo BESS ovládat přes tuto aplikaci</span>
        </div>
          
      </div>
    )
    
  }

  return (
    <>
      <div className=" bg-white rounded-xl shadow-lg flex-1  py-8 px-8">
      <h1 className="text-2xl font-bold text-center p-4 pb-0">
        Ovládání BESS
      </h1>
      <div className="flex justify-evenly flex-col h-full xs:h-auto">
        <div className="grid grid-cols-2 gap-4 p-4 pt-0 xs:grid-cols-1 xs:gap-2">
          <div className=" p-4 ">
            <div className={"flex flex-col gap-2"}>
              {generate_select(
                BatteryStatusEnum.generateSelectOptions(),
                "battery_status",
                "Změna stavu"
              )}
              {current_value_input("battery_status")}
            </div>
          </div>
          <div className=" p-4 ">
            <div className={"flex flex-col gap-2"}>
              {generate_select(
                BatteryModeEnum.generateSelectOptions(),
                "battery_mode",
                "Přepínání režimů"
              )}
              {current_value_input("battery_mode")}
            </div>
          </div>
          <div className=" p-4 ">
            <div className={"flex flex-col gap-2"}>
              {generate_select(
                BatteryControlTypeEnum.generateSelectOptions(),
                "battery_control_type",
                "Způsob ovládání"
              )}
              {current_value_input("battery_control_type")}
            </div>
          </div>
          <div className=" p-4 ">
            <div className={"flex flex-col gap-2"}>
              <Input 
              type={"number"}
              placeholder={"Činný výkon"}
              label={"Činný výkon (MIN: " + performanceMin + " kW, MAX: " + performanceMax + " kW)"}
              className={"flex-1"}
              classLabel={"flex-1 font-bold text-center text-red-500"}
              min={Math.floor(performanceMin)}
              max={Math.ceil(performanceMax)}  
              required={true}
              value={isDisabled ? 0 : control_values["battery_active_performance"]}
              onChange={(e) => {
                let value = e.target.value;

                if (isDisabled) {
                  setControlValues((prev) => {
                    return { ...prev, "battery_active_performance": 0 };
                  });

                  setChosenValues((prev) => {
                    return { ...prev, "battery_active_performance": 0 };
                  });
                } else {
                  setControlValues((prev) => {
                    return { ...prev, "battery_active_performance": value };
                  });

                  setChosenValues((prev) => {
                    return { ...prev, "battery_active_performance": value };
                  });
                }
              }}
              updateState={setIsOk}
              readOnly={
               isDisabled
              }
              />
              {current_value_input("battery_active_performance")}
            </div>
          </div>
        </div>

        <div className="flex justify-center pb-6 justify-self-end">
          <Button
            text={"Uložit"}
            class={
              "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            }
            onClick={openModal}
          ></Button>
        </div>
      </div>
      {isModalOpen && (
        <ConfirmModal
          isOpen={isModalOpen}
          onClose={closeModal}
          headline="Přejete si uložit změny?"
          text={generate_table_changes()}
          buttonText="Close"
          onConfirm={save_changes} // todo: save changes
        />
      )}
    </div>
    </>
    
  );
}
