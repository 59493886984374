export default class TimePeriod {
    periodKey;
    periodLabel;
    periodImage;

    constructor(periodKey) {

        switch (periodKey) {
            case 'day':
                this.periodLabel = 'den';
                this.periodImage = '1den.svg';
                break;
            case 'month':
                this.periodLabel = 'měsíc';
                this.periodImage = 'mesic.svg';
                break;
            case 'year':
                this.periodLabel = 'rok';
                this.periodImage = 'rok.svg';
                break;
            case 'allTime':
                this.periodLabel = 'za celou dobu';
                this.periodImage = 'celkem.svg'
                break;
            default:
                throw new Error('Illegal argument')
        }
        this.periodKey = periodKey
    }

     getEnergyLabel() {
        switch (this.periodKey) {
            case 'day':
                return 'Denní výtěžek';
            case 'month':
                return 'Měsíční výtěžek';
            case 'year':
                return 'Roční výtěžek';
            case 'allTime':
                return 'Celkový výtěžek';
            default:
                throw new Error('Illegal argument')
        }
    }
}