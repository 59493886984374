export class AnimationDeterminantsEnum {
    static POSITIVE = "POSITIVE";
    static NEGATIVE = "NEGATIVE";
    static ZERO = "ZERO";

    static UNKNOWN = null;

    static fromValue(value) {
        if (value > 0) {
            return AnimationDeterminantsEnum.POSITIVE;
        } else if (value < 0) {
            return AnimationDeterminantsEnum.NEGATIVE;
        } else if (value === 0) {
            return AnimationDeterminantsEnum.ZERO;
        } else {
            return AnimationDeterminantsEnum.UNKNOWN;
        }
    }

}