import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React from ".";
import { LoginWidget } from "./components/LoginWidget";
import { Logout } from "./components/Logout";
import { checkLogin } from "./repositories/AuthRepository";
import { HomePage } from "./components/HomePage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            checkLogin() ? (
              <HomePage />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/login" element={<LoginWidget />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
