import {
  getRefreshInterval,
  getDataGatherInterval,
} from "../repositories/ElectricityRepository";

import { getAnimationRefreshDelay, getAnimationRefreshInterval } from "../repositories/AnimationRepository";

import RefreshTooltip from "./RefreshTooltip";

export function HeaderWidget() {
  return (
    <header
      className={
        "flex flex-row justify-between items-center px-8 py-6 bg-white gradient-background xs:flex xs:flex-col xs:gap-3"
      }
    >
      <h1
        className={
          "text-xl font-bold text-custom flex flex-row items-center gap-1"
        }
      >
        DALU <span className={"text-lg font-light"}>monitorovací aplikace</span>
      </h1>
      <div className={"flex gap-8 h-16"}>
        <div className="refresh-intervals">
          <RefreshTooltip
            value={getRefreshInterval() / 1000}
            unit={"s"}
            description={"interval obnovení dat"}
          />

          <RefreshTooltip
            value={(getAnimationRefreshInterval() - getAnimationRefreshDelay()) / 1000 / 60}
            unit={"m"}
            description={"interval obnovení animace"}
          />

          <RefreshTooltip
            value={getDataGatherInterval() / 1000 / 60}
            unit={"m"}
            description={"interval sběru dat"}
          />
        </div>

        <div className="logout flex h-full justify-center items-center relative">
          <a className={"font-bold text-sm text-custom block"} href="/logout">
            Odhlásit se
          </a>
        </div>
      </div>
    </header>
  );
}
