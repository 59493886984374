export class BaseEnum {
    static toString(status) {
        return this.statuses[status] || "";
    }

    static generateSelectOptions(defaultOption = { value: "-1", label: "Vyberte hodnotu", className: "defaultOption" }) {
        return [
            defaultOption,
            ...Object.entries(this.statuses).map(([key, value]) => ({ value: parseInt(key), label: value })),
            { value: "0", label: "VYPRÁZDNIT REGISTR", className: "resetOption" }
        ];
    }
}