// export class BatteryStatusEnum {
//     static ON = 1;
//     static OFF = 2;
//     static RESET = 3;

import { BaseEnum } from "./core/BaseEnum";

//     static toString(status) {
//         switch (status) {
//             case this.ON:
//                 return "ON";
//             case this.OFF:
//                 return "OFF";
//             case this.RESET:
//                 return "RESET";
//             default:
//                 return "";
//         }
//     }

//     static generateSelectOptions(defaultOption = {}) {
//         return [
//             defaultOption,
//             { value: this.ON, label: this.toString(this.ON) },
//             { value: this.OFF, label: this.toString(this.OFF) },
//             { value: this.RESET, label: this.toString(this.RESET) },
//         ];

//     }

// }

export class BatteryStatusEnum extends BaseEnum {
    static ON = 1;
    static OFF = 2;
    static RESET = 3;

    static statuses = {
        [this.ON]: "ON",
        [this.OFF]: "OFF",
        [this.RESET]: "RESET"
    }
}