import { ApiConfig } from "../config/ApiConfig";

export async function fetchDailyMarketByDay(day) {
    const result = await fetch(ApiConfig.getFormattedURL() + `/ote/${day}`);
    if (!result.ok) {
        throw new Error('There was a problem fetching OTE data');
    }

    try {
        return await result.json();
    } catch (error) {
        return null;
    }
}