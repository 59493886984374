export class ElectricityRateDTO {
    date;
    hour;
    price;
    volume;

    constructor(data) {
        this.date = data.Date;
        this.hour = data.Hour;
        this.price = data.Price;
        this.volume = data.Volume
    }
}