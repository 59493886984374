import React from "react";

const formatNumber = (number) => {
  return new Intl.NumberFormat("cs-CZ").format(number);
};

export function ElectricityProductionStatsWidget({ electricEnergy, period }) {
  const valueWithUnit = electricEnergy.getValueWithUnit();
  const [value, unit] = valueWithUnit.split(" ");
  const formattedValue = formatNumber(Number(value));

  return (
    <li
      key={period.periodKey}
      className={"flex flex-row items-center gap-2 md:flex-col lap:flex-col"}
    >
      <img
        className="w-14 h-14 object-cover text-center mr-3 md:mr-0"
        src={"/images/icons/time_stats/" + period.periodImage}
        alt={period.periodLabel}
      />
      <div className={"w-max"}>
        <h3 className="font-light tracking-tight text-custom md:text-center">
          {period.getEnergyLabel()}
        </h3>
        <p className="text-xl font-bold text-custom">
          {formattedValue} {unit}
        </p>
      </div>
    </li>
  );
}
