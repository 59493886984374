import {ElectricityProductionStatsWidget} from "./ElectricityProductionStatsWidget";
import TimePeriod from "../DTOs/TimePeriod";
import {useEffect, useState} from "react";
import {fetchElectricityWholeProduction} from "../repositories/ElectricityRepository";
import ElectricEnergyDTO from "../DTOs/ElectricEnergyDTO";

export function ElectricityProductionStats() {
    let [productionData, setProductionData] = useState({
        day: new ElectricEnergyDTO({
            unit: '',
            value: 0
        }),
        month: new ElectricEnergyDTO({
            unit: '',
            value: 0
        }),
        year: new ElectricEnergyDTO({
            unit: '',
            value: 0
        }),
        allTime: new ElectricEnergyDTO({
            unit: '',
            value: 0
        })
    })
    useEffect(() => {
        async function fetchProduction () {
            const result = await fetchElectricityWholeProduction();
            setProductionData(result);

            console.log(result)
        }
        fetchProduction();
    }, [])
console.log(productionData.day.getValueWithUnit())
    return (

        <div>
            <div className="mx-auto">
                <ul
                    className="mx-auto grid max-w-2xl grid-cols-1 gap-x-10 gap-y-12 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-2 items-center"
                >
                    <ElectricityProductionStatsWidget period={new TimePeriod('day')} electricEnergy={productionData.day} />
                    <ElectricityProductionStatsWidget period={new TimePeriod('month')} electricEnergy={productionData.month} />
                    <ElectricityProductionStatsWidget period={new TimePeriod('year')} electricEnergy={productionData.year} />
                    <ElectricityProductionStatsWidget period={new TimePeriod('allTime')} electricEnergy={productionData.allTime} />

                </ul>
            </div>
        </div>
    )
}