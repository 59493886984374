import { Input } from "./Input";
import { Button } from "./Button";
import { useState } from "react";
import { CustomBlock } from "./CustomBlock";
import { login } from "../repositories/AuthRepository";
import { LocalStorage } from "../services/classes/localStorage";

export function LoginWidget() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, errorState] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = await login(email, password);
      if (data.success) {
        //  localStorage.setItem('loginUser', JSON.stringify(data.user))
        //  window.location.replace('/')
        LocalStorage.setItem("loginUser", data.user.email);
        window.location.replace("/");
      } else {
        errorState(data.message);
      }
    } catch (error) {
      errorState("Něco se pokazilo");
    }
  };

  return (
    <div className={"w-max flex items-center justify-center m-auto h-screen"}>
      <div
        className={
          "flex flex-col items-center max-w-xl w-max py-20 rounded-2xl p-24 justify-center"
        }
        style={customDivStyle}
      >
        <div className={"flex flex-col items-center gap-3 relative "}>
          <div
            style={icon}
            className={"px-4 py-2 rotate-6 rounded-xl shadow-md"}
          >
            <h1 className={"text-xl font-extrabold text-white text-center"}>
              D
            </h1>
          </div>
          <span className={"text-xl font-extrabold text-white"}>
            Přihlášení
          </span>
        </div>
        <div className={"flex flex-col items-center"}>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col mb-3">
              <Input
                type={"email"}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={"Email"}
                name={"email"}
                className={
                  "rounded-xl py-4 px-2 placeholder-white bg-white/[.35] font-bold text-sm text-white mt-5"
                }
              ></Input>
            </div>
            <div className="flex flex-col mb-3">
              <Input
                type={"password"}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={"Heslo"}
                name={"password"}
                className={
                  "rounded-xl py-4 px-2 placeholder-white bg-white/[.35] font-bold text-sm text-white mt-2"
                }
              ></Input>
            </div>
            <div className="text-center mt-3 ">
              <p className="text-red-950">{error}</p>
            </div>
            <div
              className={"flex flex-row items-center gap-2 mt-2 justify-center"}
            >
              <Button
                class={
                  "rounded-xl py-3 px-2 bg-white/[.35] font-bold text-sm text-white mt-3 customButton"
                }
                type={"submit"}
                text={"Přihlásit se"}
              ></Button>
            </div>
          </form>
        </div>
        <CustomBlock number={1}></CustomBlock>
        <CustomBlock number={2}></CustomBlock>
        <CustomBlock number={3}></CustomBlock>
      </div>
    </div>
  );
}

const icon = {
  background: "#c095fe",
};

const customDivStyle = {
  backdropFilter: "blur(10px)",
  background: "hsla(0, 0%, 100%, .3)",
  boxShadow: "1.375rem 1.375rem 2.175rem -.275rem rgba(0, 0, 0, .2)",
  position: "relative",
  zIndex: "1",
};
