import { ApiConfig } from "../config/ApiConfig";
import { LocalStorage } from "../services/classes/localStorage";

export async function login(email, password) {
    console.log("logging in");
    const result = await fetch(ApiConfig.getFormattedURL() + '/auth/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
    });

    console.log(result)

    if (!result.ok) {
        throw new Error('There was a problem logging in');
    }

    return result.json();
}

export function checkLogin() {
    return LocalStorage.isPresent('loginUser');
}


export function logout() {
    LocalStorage.removeItem('loginUser');
    window.location.replace('/');
}