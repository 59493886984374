import { ApiConfig } from "../config/ApiConfig";
import ElectricEnergyDTO from "../DTOs/ElectricEnergyDTO";


export async function fetchCurrentPerformance() {
    const result = await fetch(ApiConfig.getFormattedURL() + `/performance/current`);

    if (!result.ok) {
        throw new Error('Fetch current performance failed');
    }

    return result.json();
}

export async function fetchCurrentPerformancePorts() {
    const result = await fetch(ApiConfig.getFormattedURL() + `/port/performance/current`);

    if (!result.ok) {
        throw new Error('Fetch current performance failed');
    }

    return result.json();
}

export async function fetchSupplyFromMains() {
    const result = await fetch(ApiConfig.getFormattedURL() + '/battery/supply-from-mains');

    if (!result.ok) {
        throw new Error('Fetch supply from mains failed');
    }

    return result.json();

}


export async function fetchElectricityProduction(period) {
    const result = await fetch(ApiConfig.getFormattedURL() + '/electricity/production/' + period);

    if (!result.ok) {
        throw new Error('Fetch electricity production failed');
    }

    return result.json();
}


export async function fetchElectricityWholeProduction() {
    const result = await fetch(ApiConfig.getFormattedURL() + '/electricity/production/all');

    if (!result.ok) {
        throw new Error('Fetch electricity whole production failed');
    }

    const json = await result.json();

    return {
        day: new ElectricEnergyDTO(json.day),
        month: new ElectricEnergyDTO(json.month),
        year: new ElectricEnergyDTO(json.year),
        allTime: new ElectricEnergyDTO(json.allTime)
    }
}

export async function fetchObjectLiveStats() {
    const result = await fetch(ApiConfig.getFormattedURL() + '/object/live-status');

    if (!result.ok) {
        throw new Error('Fetch object live stats failed');
    }

    return result.json();
}


export async function fetchBatterySOC() {
    const result = await fetch(ApiConfig.getFormattedURL() + '/battery/soc');

    if (!result.ok) {
        throw new Error('There was a problem connection to the server')
    }

    return result.json();
}

export function getRefreshInterval() { // in ms
    return 5000
}


export function getDataGatherInterval() { // in ms
    // return 10 * 60 * 1000 // 10 minutes
    return 60 * 1000 // 1 minute
}