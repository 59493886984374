import { AnimationDeterminantsEnum } from "./core/AnimationDeterminantsEnum";

export class AnimationFVEState extends AnimationDeterminantsEnum {

    // fve cannot be negative
    // @Override
    static fromValue(value) {
        if (value > 0) {
            return AnimationDeterminantsEnum.POSITIVE;
        } else if (value === 0) {
            return AnimationDeterminantsEnum.ZERO;
        } else {
            return AnimationDeterminantsEnum.UNKNOWN;
        }
    }

}

export class AnimationBATState extends AnimationDeterminantsEnum {

}

export class AnimationACState extends AnimationDeterminantsEnum {}