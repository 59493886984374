import { ApiConfig } from "../config/ApiConfig";


export function getAnimationRefreshDelay() { // in ms
    return 10 * 1000;
}

export function getAnimationRefreshInterval() { // in ms
    const delay_ms = getAnimationRefreshDelay();
    return 60 * 1000 + delay_ms; // 1 minute and delay/1000 seconds
}

async function getNewestData(schema) {
    const result = await fetch(ApiConfig.getFormattedURL() + `/schemas/newest/${schema}`);
    if (!result.ok) {
        throw new Error('Error fetching newest data');
    }
    return result.json();
}

export async function getNewestPerformance() {
    return getNewestData("ActualPower");
}

export async function getNewestSupplyFromMains() {
    return getNewestData("SupplyFromMains");
}

export async function getNewestBatterySOC() {
    return getNewestData("BatterySoc");
}

export async function getNewestBatteryPower() {
    return getNewestData("BatteryPower");
}

export async function getNewestACVoltage() {
    return getNewestData("ACVoltage");
}

export async function getNewestPowerConsumption() {
    return getNewestData("PowerConsumption");
}


export async function getNewestDataForAnimation() {
    const fve = await getNewestPerformance();
    const supply = await getNewestSupplyFromMains();
    const batterySOC = await getNewestBatterySOC();
    const batteryPower = await getNewestBatteryPower();
    const acVoltage = await getNewestACVoltage();

    let housePower = fve.value + batteryPower.value * (-1) + acVoltage.value;

    return {
        performance: Math.round(fve.value),
        supply: Math.round(supply.value),
        batterySOC: Math.round(batterySOC.value),
        batteryPower: Math.round(batteryPower.value),
        acVoltage: Math.round(acVoltage.value),
        housePower: Math.round(housePower)
    }
}