const PROTOCOL = 'https'
const HOST = 'fve.ortnb.cz'
const PORT = null
const API_ENDPOINT = 'api'


export const CONFIG = {
    PROTOCOL,
    HOST,
    PORT,
    API_ENDPOINT
}