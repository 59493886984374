import { BaseEnum } from "./core/BaseEnum";

export class BessStatusEnum extends BaseEnum {
    static LOCAL = 0;
    static MB = 1;
    static OPCUA = 2;
    static IEC = 3;

    static statuses = {
        [this.LOCAL]: "LOCAL",
        [this.MB]: "MB",
        [this.OPCUA]: "OPCUA",
        [this.IEC]: "IEC"
    }



}