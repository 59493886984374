class LocalStorage {
    static setItem(key, value) {
        localStorage.setItem(key, value);
    }

    static getItem(key) {
        return localStorage.getItem(key);
    }

    static removeItem(key) {
        localStorage.removeItem(key);
    }

    static clear() {
        localStorage.clear();
    }

    static isPresent(key) {
        return localStorage.getItem(key) !== null;
    }

    static log() {
        console.log(localStorage);
    }
}

module.exports = {
    LocalStorage
}