export function CustomBlock(props){
    return (
        <div className={`div-custom-${props.number} div-custom`} style={customDiv}></div>
    );
}

const customDiv = {
    backdropFilter: 'blur(10px)',
    background: 'hsla(0, 0%, 100%, .3)',
    borderLeft: '1px solid hsla(0, 0%, 100%, .3)',
    borderRadius: '10px',
    borderTop: '1px solid hsla(0, 0%, 100%, .3)',
    boxShadow: '10px 10px 60px -8px rgba(0, 0, 0, .2)',
    position: 'absolute',
    transition: 'all .2s ease',
};