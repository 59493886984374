import React, { useEffect, useState } from "react";
import ProgressBar from "react-customizable-progressbar";
import { fetchCurrentPerformancem, fetchCurrentPerformancePorts } from "../repositories/ElectricityRepository";
import ElectricityPowerDTO from "../DTOs/ElectricityPowerDTO";
import { getRefreshInterval } from "../repositories/ElectricityRepository";
import {getNewestDataForAnimation} from "../repositories/AnimationRepository";

const CurrentPerformanceWidget = () => {
  let [power, setPower] = useState({
    value: 0,
    unit: "kW",
  });

  const calculateNextTimeout = () => {
    const now = new Date();
    const secondsSinceMinuteStart = now.getSeconds() + now.getMilliseconds() / 1000;
    const remainingTime = 5 - (secondsSinceMinuteStart % 5);
    return remainingTime * 1000;
  };


  async function currentPerformance() {
    try {
    //  let ports = await fetchCurrentPerformancePorts();
    //  console.log("Ports: ", ports);
      const newest_data_pack = await getNewestDataForAnimation();
      console.log("Výkon je:" + newest_data_pack.performance);
      setPower(
          new ElectricityPowerDTO({
            value: newest_data_pack.performance,
            unit: "kW",
          })
      );
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    const scheduleNextUpdate = () => {
      const timeout = calculateNextTimeout(); // Vypočítáme čas do příštího 10s intervalu
      setTimeout(() => {
        currentPerformance();
        scheduleNextUpdate(); // Naplánujeme další volání po příštím 10s intervalu
      }, timeout);
    };

    currentPerformance(); // První vykonání hned
    scheduleNextUpdate(); // Naplánování následných volání

    return () => clearTimeout(scheduleNextUpdate); // Cleanup při odpojení komponenty
  }, []);

  return (
      <div
          className={
            "flex flex-col items-center justify-center mt-14 px-8 flex-2 bg-gray-100 rounded-2xl flex-1 xs:mt-0 xs:py-4 md:basis-2/5 md:mt-0"
          }
      >
        <h1 className={"text-2xl text-black font-bold text-center mt-2"}>
          Aktuální výkon
        </h1>
        <ProgressBar
            radius={100}
            progress={power.value}
            cut={120}
            steps={855}
            rotate={-210}
            strokeWidth={30}
            strokeColor="#4b3bff"
            strokeLinecap="square"
            trackStrokeWidth={30}
            trackStrokeColor="#e6e6e6"
            trackStrokeLinecap="square"
            pointerRadius={0}
            initialAnimation={true}
            transition="1.5s ease 0.5s"
            trackTransition="0s ease"
        >
          <div className="indicator-volume">
            <div className="inner">
              <div
                  className="icon flex flex-col items-center absolute"
                  style={textStyle}
              >
                <div className="text-xl font-bold">
                  {power.value} {power.unit}
                </div>
                <div className="text-sm">Aktuální výkon</div>
              </div>
            </div>
            <p className={"font-bold text-center"} style={textUnderStyle}>
              Velikost systému 855 kW
            </p>
          </div>
        </ProgressBar>
</div>
)
  ;
};

export default CurrentPerformanceWidget;

const textStyle = {
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#4b3bff",
  textAlign: "center",
};

const textUnderStyle = {
  color: "#4b3bff",
  textAlign: "center",
  position: "absolute",
  bottom: "0",
  left: "50%",
  transform: "translateX(-50%)",
  width: "100%",
};
