import React from "react";
import Lottie from "lottie-react";

import loadingAnimation from "../animations/loading.json";

function getValueWithUnit(key, data){
  if(!(key in data)){
    return "N/A";
  }

  return `${data[key]} ${data.units[key]}`;
} 

const Animation = ({ animationData, heading = "Průběh" ,technicalData = {} }) => {

  const isLoading = animationData == loadingAnimation;

  const keys = Object.keys(technicalData).filter(key => key !== "units");

  return (
    <div className=" max-w-2xl  rounded-xl bg-white py-8 px-8 min-width-full relative">
        {
          keys.map((key, index) => {
            return (
              <div className={`stat absolute z-10 ${key} ${isLoading ? "hidden" : ""}`} key={key}>
                <p className="text-2xl font-bold text-center xs:text-xl">{getValueWithUnit(key, technicalData)}</p>
              </div>
            )
          })
        }
      <h1 className="text-2xl font-bold text-center p-4 pb-0">{heading}</h1>
      <Lottie
        className={"!bg-color-none lottie-container"}
        animationData={animationData}
        loop={true}
        autoplay={true}
      />
    </div>
  );
};

export default Animation;
