export class WeatherDayDTO {
    date;
    day = {
        maxtemp_c: '',
        mintemp_c: '',
        avgtemp_c: '',
        totalprecip_mm: '',
        totalsnow_cm: '',
        maxwind_kph: '',
        avghumidity: '',
        daily_will_it_rain: '',
        daily_chance_of_rain: '',
        daily_will_it_snow: '',
        daily_chance_of_snow: '',
        condition: {
            text: '',
            icon: '',
            code: ''
        },
        uv: ''
    }

    astro = {
        sunrise: '',
        sunset: '',
        moonrise: '',
        moonset: '',
        moon_phase: '',
        moon_illumination: ''
    }

    location = {
        name: 'Hořice',
        lat: '50.37',
        lon: '15.63'
    }

    constructor(data) {
        if(data) {
            const forecast = data.forecast.forecastday[0];
            this.date = new Date(forecast.date);
            this.day = forecast.day;
            this.astro = forecast.astro;
            this.astro.sunrise = this.convertTime12to24(this.astro.sunrise)
            this.astro.sunset = this.convertTime12to24(this.astro.sunset)
            this.location = data.location;
        }
    }

     getFormattedDate() {
        if(!this.date) {
            return '';
        }
        return `${this.date.getUTCDate()}.${this.date.getUTCMonth() + 1}.${this.date.getFullYear()}`;
    }

     convertTime12to24(time12) {
        if(!time12) {
            return
        }

        const [time, modifier] = time12.split(' ');

        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}`;
    }
}