export default function RefreshTooltip(prop) {
  return (
    <div className={"font-light text-sm text-custom flex "}>
      <div className={"font-bold text-custom val"}>
        {prop.value} {prop.unit}
      </div>
      <div className={"desc "}>{prop.description}</div>
    </div>
  );
}
