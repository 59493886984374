import React from 'react';
import { useState } from 'react';

export function Input(props) {
  const {
    label,
    classLabel,
    required,
    placeholder,
    className,
    type = 'text', // Default to 'text' if not specified
    name,
    value,
    onChange,
    readOnly,
    disabled,
    min = -Infinity, // Default min to negative infinity
    max = Infinity, // Default max to positive infinity
    updateState = () => {},
  } = props;

  const [inputValue, setInputValue] = useState(value);

  const [error, setError] = useState('');

  const generate_error_msg = (min, max) => {
    if(min === -Infinity){
      min = '-∞';
    }
    if(max === Infinity){
      max = '∞';
    }

    return `Hodnota musí být v rozmezí ${min} a ${max}`;
  }

  const raiseError = (msg) => {
    updateState(false);
    setError(msg);
  }

  const clearError = () => {
    updateState(true);
    setError('');
  }


  const handleChange = (e) => {

    setInputValue(e.target.value);

    if(inputValue === '') {
      clearError();
    }

    if((min !== -Infinity && e.target.value < min) || (max !== Infinity && e.target.value > max)) {
      raiseError(generate_error_msg(min, max));
    }else{
      clearError();
    }

    if (onChange) {
      onChange(e);
    }
  }

  return (
    <>
      {label && <label className={classLabel}>{label}</label>}
      <input
        required={required}
        placeholder={placeholder}
        className={className + (error ? ' border-red-500 border-2 rounded-xl' : '')}
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        readOnly={readOnly}
        disabled={disabled}
        min={type === 'number' ? min : undefined} // Set min only for number type
        max={type === 'number' ? max : undefined} // Set max only for number type
      />
      {error && <p className="text-red-500">{error}</p>}
    </>
  );
}

