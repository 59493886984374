export function Overview({ children }) {
  return (
    <div
      className={
        "flex flex-column items-center px-8 mt-14 py-8 flex-1 bg-gray-100 rounded-2xl flex-2 justify-center xs:mt-0 md:basis-full md:mt-0 md:basis-2/5 lap:mt-0 lap:basis-1/2"
      }
    >
      {children}
    </div>
  );
}
